import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';

//apollo client  helpers
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import store from './redux/store';

//configuration
import configuration from './config/configuration';

import App from './App';


const link = from([
  new HttpLink({
    // uri: 'https://leerecs.net//backend/public/graphql',
    uri: `${configuration.GRAPHQL_SERVER_LINK}`,
    credentials: 'include',
  }),
]);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);


