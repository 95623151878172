// @ts-nocheck
import React, { useEffect, useState } from "react";
import configuration from "../../../../config/configuration";

//icons
import LockIcon from "./src/Lock";
import FacebookIcon from "./src/Fb";
import SpotifyIcon from "./src/Spotify";
import TwitterIcon from "./src/Twitter";
import MicIcon from "./src/Mic";
import PlaylistIcon from "./src/Playlist";
import InstagramIcon from "./src/Instagram";
import YouTubeIcon from "./src/YouTube";
import SpreakerIcon from "./src/Spreaker";
import OnlineRadioIcon from "./src/OnlineRadio";
import MusicIcon from "./src/Music";
import CartIcon from "./src/Cart";
import IheartIcon from "./src/iHeart";
import FmOnlineIcon from "./src/FmOnline";
import AmazonMusicIcon from "./src/AmazonMusic";
import PodcastsIcon from "./src/Podcasts";
import CastboxIcon from "./src/Castbox";
import SoundcloudIcon from "./src/Soundcloud";
import DeezerIcon from "./src/Deezer";
import TiktokIcon from "./src/Tiktok";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { Box } from "./src/Box";

import "./index.scss";



const SocialMedia = () => {
  const [state, setstate] = useState(false)
  const [platiforms,setPlatiforms] =useState(null)

  useEffect(() => {
   
    gsap.registerPlugin(ScrollTrigger);
    var timeline = gsap.timeline();
    ScrollTrigger.create({
      trigger: "#social-media-page-home",
      animation: timeline,
      start: "top center+=300px",
      end: "top center",
      onEnter: () => {
        setstate(true)
      },
    });
    
  }, [])
  const [info, setinfo] = useState(null);
  useEffect(() => {
    const getPlatiforms =async () => {
      try{

        let res =await fetch(`${configuration.BACK_END_HOST}/allplatiform`);
        let resJson = await res.json()
        setPlatiforms(resJson)
        // console.log(resJson)
       
      }catch(error){
        console.log(error)
      }
      
    }
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          
        );
        let responseJson = await response.json();
        setinfo(responseJson);
      } catch (error) {
        console.error(error);
      }
    }
    getMoviesFromApi()
    getPlatiforms();
  }, []);
  return (
    <div className="container-fluid" id="social-media-page-home">
      <div className="header d-flex justify-content-between">
        <div>
          <h1 className="text-lg text-light main-title">
            Distribution
            <br />
            Platforms
          </h1>
          <p className="text-muted description-text">
            Leerecs wants to empower now and independent artists making
            alternative music.
            <br />
            Consider us an ally in your music career, Leerecs will always be on
            the artist's side,
            <br />
            providing a platform where independent original music of quality
            always
            <br />
            goes first... Streaming, Podcast, Talk Radio, Record Publishing and
            more
          </p>
        </div>
        <div className="right-content">
          <div className="big-text-wrapper">
            <div className="music-text">Platforms</div>
          </div>
        </div>
      </div>
      {
        platiforms && (<div className="main-card-wrapper">
          {/* {console.log(platforms)} */}
          {platiforms.data.map((item,idx)=>{
            
            return (<Box icon={item.icon} count={state && item.number} text={item.title} link={item.url} key={idx} title={item.item} />)
          })}
          
        </div>)
      }
    </div>
  );
};

export default SocialMedia;
