import React from 'react'
import { useHistory } from 'react-router-dom'
import { BASE, Routes, useFetcher } from '../testimonial/utils/api'
import { BoxLoading } from "react-loadingg";
import "./index.scss"

export default function Cta() {
    const history=useHistory()
    const { data, loading }=useFetcher(Routes.cta)
    if(loading){
        return (<div className="testimonial-preloader" style={{
            width: "100%",
            height: "50vh",
            display: "grid",
            placeItems: "center"
        }}>
            <BoxLoading color="#F8107D" />
        </div>)
    }
    if(data){
        const attributes=data.data.attributes
        return (
            <div id="cta-production" style={{
                backgroundImage: `url("${BASE}${attributes.background_img.data.attributes.url}"),
        linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.16) 100%)`
            }}>
                <div className="cta-title">{attributes.title}</div>
                <div className="cta-text">
                    {attributes.description}
                </div>
                <div className="btn btn-cta" onClick={() => {
                    window.location.href = attributes.request_link
                }}>
                    <div className="d-flex">
                        <img src={`${BASE}${attributes.icon.data.attributes.url}`} alt="cta-arrow" />
                        <div className="text-cta">{attributes.icon_text}</div>
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

