// @ts-ignore
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nextRoute: null,
  sharedUrl: null,
};

export const Index = createSlice({
  name: "uidata",
  initialState,
  reducers: {
    setnextRoute: (state, action) => {
      state.nextRoute = action.payload;
    },
    setsharedUrl: (state, action) => {
      state.sharedUrl = action.payload;
    },
  },
});

export const { setnextRoute, setsharedUrl } = Index.actions;

export default Index.reducer;
