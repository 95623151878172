import React,{useState,useEffect} from "react";
import "./share.scss";
import ClosePanelIcon from "./src/ClosePanelIcon";
import SmallForwardArrow from "../../../main/components/welcome/src/smallForwardArrow";
import SmallBackArrow from "../../../main/components/welcome/src/SmallBackArrow";

// redux functionality
import { useAppDispatch,useAppSelector } from "../../../../redux/hooks";
import { setsharedUrl } from "../../../../redux/data/artistProfile/index";

import { useHistory } from "react-router";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  WeiboShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

// apollo client

export default function Share() {
  const dispatch = useAppDispatch();
  const [copy,setCopy]=useState(false)
  const [state,setstate]=useState(0)
  const { sharedUrl } = useAppSelector((state) => state.artistProfile);
  const { musicProfile} = useAppSelector(
    (state) => state.musicPlayerData
  );
  return (
    <div id="share">
      <div className="panel">
        <div className="d-flex justify-content-between top-button">
          <div className="title">Share Music</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              dispatch(setsharedUrl(null));
            }}
          >
            <ClosePanelIcon />
          </div>
        </div>
        {/* <div className="header-img" style={{backgroundImage:`url(${musicProfile.imageUrl})`}}></div>
        <div className="prerequist">
          {musicProfile.title}
           </div> */}
         <div className=" desktop">
         <div className="d-md-flex justify-content-between">
        <div className="actual-content">
          <div className="d-flex justify-content-between">
            <div className="back-arrow">
              {(state !== 0) && (<div onClick={() => { setstate(state - 1) }} className="icons">
                <div className="arrows">
                <SmallBackArrow/>
                </div>
              </div>)}
            </div>
            <div id="share-icon">
              {(state === 0) && (
                <div className=" d-flex sharelist mx-4" style={{}}>
                   <FacebookShareButton
            title={"Facebook"}
            url={sharedUrl}
            quote={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            hashtag="#Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
          >
            <FacebookIcon size={45} round={true} />
          </FacebookShareButton>
          <EmailShareButton
            title={"Email"}
            url={sharedUrl}
            subject={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            body={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            separator={" "}
          >
            <EmailIcon size={45} round={true} />
          </EmailShareButton>
          <LinkedinShareButton
            title={musicProfile.title}
            url={sharedUrl}
            summary={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            source={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <LinkedinIcon size={45} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton
            title={musicProfile.title}
            url={sharedUrl}
            via={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            hashtags={[
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
            ]}
            related={[
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
            ]}
          >
            <TwitterIcon size={45} round={true} />
          </TwitterShareButton>
          <TelegramShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <TelegramIcon size={45} round={true} />
          </TelegramShareButton>
          <WhatsappShareButton
            title={musicProfile.title}
            separator=" "
            url={sharedUrl}
          >
            <WhatsappIcon size={45} round={true} />
          </WhatsappShareButton>
                </div>)}
              {(state === 1) && (
                <div className="d-flex sharelist mx-4" style={{}}>
                           <LineShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <LineIcon size={45} round={true} />
          </LineShareButton>
          <PocketShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <PocketIcon size={45} round={true} />
          </PocketShareButton>
          <InstapaperShareButton
            title={musicProfile.title}
            description={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            url={sharedUrl}
          >
            <InstapaperIcon size={45} round={true} />
          </InstapaperShareButton>
          <FacebookMessengerShareButton
            appId={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            title="Facebook Messenger"
            url={sharedUrl}
            redirectUri={sharedUrl}
            to={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <FacebookMessengerIcon size={45} round={true} />
          </FacebookMessengerShareButton>
          <ViberShareButton
            title={musicProfile.title}
            url={sharedUrl}
            separator={" "}
          >
            <ViberIcon size={45} round={true} />
          </ViberShareButton>
          <RedditShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <RedditIcon size={45} round={true} />
          </RedditShareButton>
                </div>)}
              {(state === 2) && (
                <div className="d-flex sharelist mx-4" style={{}}>
                  
          <PinterestShareButton
            media={sharedUrl}
            title="Pinterest"
            url={sharedUrl}
            description={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <PinterestIcon size={45} round={true} />
          </PinterestShareButton>
          <TumblrShareButton
            title={musicProfile.title}
            url={sharedUrl}
            tags={[
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
            ]}
            caption={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            posttype={"link"}
          >
            <TumblrIcon size={45} round={true} />
          </TumblrShareButton>
          <VKShareButton
            title={musicProfile.title}
            url={sharedUrl}
            image={sharedUrl}
            noParse={true}
            noVkLinks={false}
          >
            <VKIcon size={45} round={true} />
          </VKShareButton>
          <OKShareButton
            title={musicProfile.title}
            url={sharedUrl}
            description={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            image={sharedUrl}
          >
            <OKIcon size={45} round={true} />
          </OKShareButton>
          <WorkplaceShareButton
            title={"Workplace"}
            url={sharedUrl}
            quote={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            hashtag={
              "#Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <WorkplaceIcon size={45} round={true} />
          </WorkplaceShareButton>
          <HatenaShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <HatenaIcon size={45} round={true} />
          </HatenaShareButton>
                </div>
              )}
              {/* <div className="carousel-circle">
                <div className="carousel-container d-flex">
                  <div className={`circle rounded-circle ${(state === 0) && "active"}`} onClick={() => { setstate(0) }}></div>
                  <div className={`circle rounded-circle ${(state === 1) && "active"}`} onClick={() => { setstate(1) }}></div>
                  <div className={`circle rounded-circle ${(state === 2) && "active"}`} onClick={() => { setstate(2) }}></div>
                </div>
              </div> */}
            </div>
            <div className="forwardarrow">
              {(state !== 2) && (<div onClick={() => { setstate(state + 1) }} className="icons">
                <div className="arrows right-arrow">
                <SmallForwardArrow />
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
         </div>
       
         <div className=" mobile">
         <div className="d-md-flex justify-content-between">
        <div className="actual-content">
          <div className="d-flex justify-content-between">
            <div className="back-arrow">
              {(state !== 0) && (<div onClick={() => { setstate(state - 1) }} className="icons">
                <div className="arrows">
                <SmallBackArrow/>
                </div>
              </div>)}
            </div>
            <div id="share-icon">
              {(state === 0) && (
                <div className=" d-flex sharelist mx-4" style={{}}>
                   <FacebookShareButton
            title={"Facebook"}
            url={sharedUrl}
            quote={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            hashtag="#Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
          >
            <FacebookIcon size={45} round={true} />
          </FacebookShareButton>
          <EmailShareButton
            title={"Email"}
            url={sharedUrl}
            subject={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            body={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            separator={" "}
          >
            <EmailIcon size={45} round={true} />
          </EmailShareButton>
          <LinkedinShareButton
            title={musicProfile.title}
            url={sharedUrl}
            summary={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            source={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <LinkedinIcon size={45} round={true} />
          </LinkedinShareButton>
          
                </div>)}
                {(state === 1) && (
                <div className=" d-flex sharelist mx-4" style={{}}>
          <TwitterShareButton
            title={musicProfile.title}
            url={sharedUrl}
            via={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            hashtags={[
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
            ]}
            related={[
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
            ]}
          >
            <TwitterIcon size={45} round={true} />
          </TwitterShareButton>
          <TelegramShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <TelegramIcon size={45} round={true} />
          </TelegramShareButton>
          <WhatsappShareButton
            title={musicProfile.title}
            separator=" "
            url={sharedUrl}
          >
            <WhatsappIcon size={45} round={true} />
          </WhatsappShareButton>
                </div>)}
              {(state === 2) && (
                <div className="d-flex sharelist mx-4" style={{}}>
                           <LineShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <LineIcon size={45} round={true} />
          </LineShareButton>
          <PocketShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <PocketIcon size={45} round={true} />
          </PocketShareButton>
          <InstapaperShareButton
            title={musicProfile.title}
            description={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            url={sharedUrl}
          >
            <InstapaperIcon size={45} round={true} />
          </InstapaperShareButton>
          
                </div>)}

                {(state === 3) && (
                <div className="d-flex sharelist mx-4" style={{}}>
                           <FacebookMessengerShareButton
            appId={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            title="Facebook Messenger"
            url={sharedUrl}
            redirectUri={sharedUrl}
            to={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <FacebookMessengerIcon size={45} round={true} />
          </FacebookMessengerShareButton>
          <ViberShareButton
            title={musicProfile.title}
            url={sharedUrl}
            separator={" "}
          >
            <ViberIcon size={45} round={true} />
          </ViberShareButton>
          <RedditShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <RedditIcon size={45} round={true} />
          </RedditShareButton>
                </div>)}
              {(state === 4) && (
                <div className="d-flex sharelist mx-4" style={{}}>
                  
          <PinterestShareButton
            media={sharedUrl}
            title="Pinterest"
            url={sharedUrl}
            description={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <PinterestIcon size={45} round={true} />
          </PinterestShareButton>
          <TumblrShareButton
            title={musicProfile.title}
            url={sharedUrl}
            tags={[
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
            ]}
            caption={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            posttype={"link"}
          >
            <TumblrIcon size={45} round={true} />
          </TumblrShareButton>
          <VKShareButton
            title={musicProfile.title}
            url={sharedUrl}
            image={sharedUrl}
            noParse={true}
            noVkLinks={false}
          >
            <VKIcon size={45} round={true} />
          </VKShareButton>

                </div>
              )}

{(state === 5) && (
                <div className="d-flex sharelist mx-4" style={{}}>
          <OKShareButton
            title={musicProfile.title}
            url={sharedUrl}
            description={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            image={sharedUrl}
          >
            <OKIcon size={45} round={true} />
          </OKShareButton>
          <WorkplaceShareButton
            title={"Workplace"}
            url={sharedUrl}
            quote={
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
            hashtag={
              "#Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam"
            }
          >
            <WorkplaceIcon size={45} round={true} />
          </WorkplaceShareButton>
          <HatenaShareButton
            title={musicProfile.title}
            url={sharedUrl}
          >
            <HatenaIcon size={45} round={true} />
          </HatenaShareButton>
                </div>
              )}
              {/* <div className="carousel-circle">
                <div className="carousel-container d-flex">
                  <div className={`circle rounded-circle ${(state === 0) && "active"}`} onClick={() => { setstate(0) }}></div>
                  <div className={`circle rounded-circle ${(state === 1) && "active"}`} onClick={() => { setstate(1) }}></div>
                  <div className={`circle rounded-circle ${(state === 2) && "active"}`} onClick={() => { setstate(2) }}></div>
                </div>
              </div> */}
            </div>
            <div className="forwardarrow">
              {(state !== 5) && (<div onClick={() => { setstate(state + 1) }} className="icons">
                <div className="arrows right-arrow">
                <SmallForwardArrow />
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
         </div>
         
         <div class="input-group mb-3 mt-3 px-3" style={{cursor:"pointer",width:"100%"}}>
  <input type="text" class="form-control" disabled placeholder={sharedUrl} aria-label="Recipient's username" aria-describedby="basic-addon2" style={{background:"black",color:"white", borderRight:"none"}}/>
  <span class="input-group-text cpy-btn" id="basic-addon2" style={{background:"black",color:"white",borderRadius:"0px"}} 
  onClick={(e)=>{
    navigator.clipboard.writeText(sharedUrl);
    setCopy(true)
  }
    }>{copy?"Copied":"Copy"}
      </span>
</div>
      </div>
    </div>
  );
}
