'use client';
import { useState } from 'react';

import { useFetcher, poster, BASE, Routes } from './utils/api'
import styles from './testimonial.module.scss'
import { BoxLoading } from "react-loadingg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export const Testimonial = () => {
	const { data, loading,error } = useFetcher(Routes.testimonial)
	 
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
			slidesToSlide: 3, // Number of slides to move when a slide is clicked
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			slidesToSlide: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
		},
	};

	const CustomLeftArrow = ({ onClick, ...rest }) => {
		return <></>;
	};

	const CustomRightArrow = ({ onClick, ...rest }) => {
		return <></>;
	};

	const CustomDot = ({ onClick, ...rest }) => {
		{ console.log("teh rest of items", rest) }
		return <div className={`${styles.customDot} ${rest.active && styles.active}`} onClick={onClick} {...rest} />;
	};
	if (loading) {
		return (
			<div className="testimonial-preloader" style={{
				width: "100%",
				height: "50vh",
				display: "grid",
				placeItems: "center"
			}}>
				<BoxLoading color="#F8107D" />
			</div>
		)
	}
	
	if (data)
		return (
			<section className='relative flex flex-col gap-8 h-max py-12 lg:py-[4vw] px-[15px] lg:px-[8vw] m-auto w-100'>
				<div className="workings-title">
					<h1 className="text-lg text-light main-title">
						Testimonials
					</h1>
					<p>Thanks for stopping by, feel free to contact us with any question or collaboration proposal using our contact form. Cheers!</p>
				</div>
				<div className={styles.testimonial}>
					<Carousel
						swipeable={true}
						draggable={true}
						showDots={true}
						responsive={responsive}
						infinite={true}
						autoPlay={true}
						autoPlaySpeed={3000}
						keyBoardControl={true}
						customTransition="transform 300ms ease-in-out"
						transitionDuration={300}
						containerClass="carousel-container"
						removeArrowOnDeviceType={["tablet", "mobile"]}
						deviceType={"desktop"}
						dotListClass="custom-dot-list-style"
						itemClass="carousel-item-padding-40-px"
						customLeftArrow={<CustomLeftArrow />}
						customRightArrow={<CustomRightArrow />}
						customDot={<CustomDot />}
					>
						{data.data.map((item, idx) => {
							return (
								<SingleTestimonial item={item} idx={idx} key={idx} />
							)
						})}
					</Carousel>
				</div>

			</section>
		)
	return <></>
}


export const Star = (props) => {
	if (props.stroke) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.02" viewBox="0 0 22 21.02">
				<path id="Path_77" data-name="Path 77" d="M12,2l3.09,6.26L22,9.27l-5,4.87,1.18,6.88L12,17.77,5.82,21.02,7,14.14,2,9.27,8.91,8.26Z" transform="translate(-1 -1)" fill="none" stroke="#F8107D" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" />
			</svg>
		)
	}
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.02" viewBox="0 0 22 21.02">
			<g id="Group_351" data-name="Group 351" transform="translate(-1 -1)">
				<path id="Path_77" data-name="Path 77" d="M12,2l3.09,6.26L22,9.27l-5,4.87,1.18,6.88L12,17.77,5.82,21.02,7,14.14,2,9.27,8.91,8.26Z" fill="#F8107D" stroke="#F8107D" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" />
			</g>
		</svg>
	)
}

export const Marks = () => {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="57.037" height="53.008" viewBox="0 0 57.037 53.008">
		<g id="quotation-marks-svgrepo-com" opacity="0.25">
			<path id="Path_76" data-name="Path 76" d="M24.559,225.119v9.517a2.338,2.338,0,0,1-2.338,2.338c-4.606,0-7.11,4.723-7.457,14.044H22.22a2.338,2.338,0,0,1,2.338,2.339v20.1a2.337,2.337,0,0,1-2.338,2.337H2.338A2.337,2.337,0,0,1,0,273.453v-20.1a51.575,51.575,0,0,1,1.337-12.194,29.372,29.372,0,0,1,4.149-9.647,20.263,20.263,0,0,1,7.054-6.444,20.075,20.075,0,0,1,9.682-2.291A2.337,2.337,0,0,1,24.559,225.119ZM54.7,222.782a19.41,19.41,0,0,0-16.736,8.735,29.377,29.377,0,0,0-4.15,9.65,51.551,51.551,0,0,0-1.336,12.191v20.1a2.337,2.337,0,0,0,2.337,2.337H54.7a2.337,2.337,0,0,0,2.337-2.337v-20.1a2.337,2.337,0,0,0-2.337-2.339H47.348c.342-9.322,2.811-14.044,7.352-14.044a2.338,2.338,0,0,0,2.337-2.338v-9.517A2.335,2.335,0,0,0,54.7,222.782Z" transform="translate(0 -222.782)" fill="#F8107D" />
		</g>
	</svg>
	)
}

function SingleTestimonial({idx, item}) {
	return <div className="single-testimonial" key={idx}>
		<div className="user-image" style={{
			backgroundImage: `url(${BASE}${item.attributes.user_iamge.data.attributes.url})`
		}}>
		</div>
		<div className="testimonial-svg">
			<Marks />
		</div>
		<div className="testimonial">
			{item.attributes.testimonial}
		</div>
		<hr />
		<div className="user-details">
			<div className="user">
				<div className="username">
					{item.attributes.username}
				</div>
				<div className="occupation">
					{item.attributes.occupation}
				</div>
			</div>
			<div className="stars">
				{[...Array(5)].map((star, idx) => {
					if (idx < item.attributes.rating) {
						return (
							<Star key={idx} />
						);
					}
					else {
						return <Star key={idx} stroke={true} />;
					}
				})}
			</div>
		</div>
	</div>;
}
