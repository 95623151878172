// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { gql, ApolloError } from "@apollo/client";

//helpers
import TextareaAutosize from "react-textarea-autosize";
// @ts-nocheck
import { useMutation } from "@apollo/client";

import "./index.scss";

import Sucess from "../../../../components/sucess/index";
import Loading from "../../../../components/loading/index";
import Error from "../../../../components/error/index";

import ReCAPTCHA from "react-google-recaptcha";

import configuration from "../../../../config/configuration";

const Contact = () => {
  //captch ref
  const reRef = useRef();
  const [errorData, seterrorData] = useState();
  const [close, setClose] = useState(false);

  const value = (id: string) => {
    var inputValue = (document.getElementById(id) as HTMLInputElement).value;
    return inputValue;
  };
  //apollo client initialization
  const MUTATION = gql`
    mutation (
      $email: String!
      $subject: String!
      $details: String!
      $token: String!
    ) {
      SenddEmail(
        userInfo: {
          email: $email
          subject: $subject
          details: $details
          token: $token
        }
      ) {
        desc
      }
    }
  `;
  const [SenddEmail, { loading, data, error }] = useMutation(MUTATION, {
    errorPolicy: "all",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    if (!token) {
      return seterrorData("No authorized by google captcha");
    }
    if (token) {
      console.log(token)
      const [email, subject, desc] = [
        value("email"),
        value("subject"),
        value("desc"),
      ];
      try {
        const result = await SenddEmail({
          variables: {
            email: email,
            subject: subject,
            details: desc,
            token: token,
          },
        });
        setClose(true)
        if (result) {
          if (data) {
            
          }
        }
        
      } catch (error) { }
    }
  };
  return (
    <div className="container-fluid" id="contact-page-home">
      <div className="d-md-flex justify-content-between">
        <div className="text-items">
          <div>
            <h1 className="text-lg text-light main-title">Contact Us</h1>
            <p className="text-muted description-text">
              Thanks for stopping by, feel free to contact us with any question or
              collaboration proposal using our contact form.
              Cheers!
            </p>
            <div className="owl-img-wrapper"></div>
          </div>
        </div>
        <div className="contact-form ">
          <div className="contact-banner">Contact</div>
          <form onSubmit={handleSubmit}>
            <div style={{minWidth:"100%"}}>
              {loading && <Loading></Loading>}
              {(data&&close) && (
                <Sucess close={setClose} text="Your request has been sent."></Sucess>
              )}
              {error &&
                error.graphQLErrors.map(({ message }, i) => {
                  console.log(error)
                  return(
                  <Error error={message}></Error>
                )})}
            </div>
            <input
              placeholder="Email"
              type="text"
              className="input-style"
              id="email"
            />
            <br />
            <input
              type="Subject"
              placeholder="Subject"
              className="input-style"
              id="subject"
            />
            <br />
            <TextareaAutosize placeholder="Details" minRows={2} id="desc" />
            <div className="col-md-12 recaptcha">
              <ReCAPTCHA
                sitekey={configuration.RECAPTCHA_SECRET_KEY}
                ref={reRef}
                theme="dark"
                size="invisible"
              />
            </div>
            <button className="send-button" type="submit">
              <div className="send-txt">Send Message</div>
              <div className="send-icon"></div>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
