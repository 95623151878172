/* eslint-disable import/prefer-default-export */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import MusicState from "../../../types/musicData"
type MusicProps=MusicState & {playMusic:boolean}
interface playMusicType{
  playMusic:boolean
}
const initialState: MusicProps = {
  musicUrl:
    "",
  imageUrl:
    "",
  artist: "",
  musicName: "",
  playMusic: false,
};

export const appData = createSlice({
  name: "musicPlaying",
  initialState,
  reducers: {
    addMusic: (state, action: PayloadAction<MusicState>) => {
      state.musicUrl = action.payload.musicUrl;
      state.imageUrl = action.payload.imageUrl;
      state.artist = action.payload.artist;
      state.musicName = action.payload.musicName;
    },
    playMusic: (state, action: PayloadAction<playMusicType>) => {
      state.playMusic = action.payload.playMusic;
    },
  },
});

export const { addMusic, playMusic } = appData.actions;

export default appData.reducer;
