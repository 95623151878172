// @ts-nocheck
import { useEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from 'react-ga4';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons'; 
import HomePage from "./pages/main/index";
import MusicOfficialPlayer from "./components/musicPageComponents/musicPlayer/index";
import { useAppSelector } from "./redux/hooks";
import ProductionService from "./pages/production";
import "./App.scss";
import Share from "./pages/artistProfilepage/components/artistCard/Share";
library.add(fab, fas);
export default function App() {
  const { musicData } = useAppSelector((state) => state.musicPlayerData);
  const { sharedUrl } = useAppSelector((state) => state.artistProfile);
  
  useEffect(() => {
    ReactGA.initialize('G-SBS20ZV1C4');
  }, []);
  
  

  ReactGA.send({
    hitType:"pageview",
    page:window.location.pathname}
  )
  return (
    <>
        <Router> 
        {sharedUrl && <Share />}
        {musicData[0].musicSrc && <MusicOfficialPlayer />}
        <Switch>
          <Route exact path="/" component={HomePage} /> 
          <Route path="/production" component={ProductionService} />
        </Switch>
      </Router>
      
    </>
  );
}
