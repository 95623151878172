import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchResult {
    searchQuery: String;
}

const initialState: SearchResult = {
    searchQuery:"",
}

export const PlayListData = createSlice({
    name: "uidata",
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<SearchResult>) => {
            state.searchQuery = action.payload.searchQuery;
        }
    },
})

export const { setQuery } = PlayListData.actions

export default PlayListData.reducer