import React from "react";

import "./SingleMusic.scss";
import PlayMusic from "./assets/PlaySingleMusic";
import configuration from "../../../../config/configuration";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { playSingle } from "../../../../redux/data/musicPlayer/index";

export default function SingleMusic({
  image,
  idx,
  title,
  username,
  file,
  profile,
}) {
  const dispatch = useAppDispatch();
  return (
    <div
      id="singleFeatureMusic"
      style={{
        backgroundImage: `url("${configuration.FILE_STORAGE_LINK + image}")`,
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="numbering">{idx}</div>
        <div
          className="play-icon"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              playSingle({
                value: [
                  {
                    name: title,
                    singer: username,
                    cover: image,
                    musicSrc: configuration.FILE_STORAGE_LINK + file,
                  },
                ],
              })
            );
          }}
        >
          <PlayMusic />
        </div>
      </div>
      <div className="description">
        <div className="d-flex justify-content-between align-content-center">
          <div className="description-wrapper">
            <div className="single-feature-title">{title}</div>
            <div className="single-feature-artist">{username}</div>
          </div>
          <a href={`/artist/${username}`} target="_blank" rel="noreferrer">
            <div
              className="rounded-circle"
              style={{
                backgroundImage: `url("${
                  profile
                    ? configuration.FILE_STORAGE_LINK + profile
                    : "https://joeschmoe.io/api/v1/random"
                }")`,
              }}
            ></div>
          </a>
        </div>
      </div>
    </div>
  );
}
