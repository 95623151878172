import React from 'react'
import guitor from "../../../../../electric4.svg"

export default function Guitor() {
  return (
    <div className='mr-3' style={{background:`url(${guitor})`, width:"26px", height:"26px", backgroundSize:"cover" ,color:"white"}}>
      
    </div>
  )
}
