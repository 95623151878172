// @ts-nocheck
import { useEffect ,useState} from "react";
import Welcome from "./components/welcome/Welcome";
import MusicianEvents from "./components/eventsSection/Events";
import SocialMedia from "./components/socialMediaSection/SocialMedia";
import StartCareer from "./components/startCareerSection/StartCareer";
import Contact from "./components/contactSection/Contact";
import Footer from "./components/footerSection/Footer";
import { useLocation } from "react-router-dom";

import "./index.scss";
import PhysicalMedia from "./components/startCareerSection/physicalMedia";

export default function Main() {
  const[info,setInfo]=useState(null)
  const[all,setAll] = useState(null)
  const location = useLocation();
  useEffect(() => {
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          "https://play.leerecs.com/api/content"
        );
        let responseJson = await response.json();
        setAll(responseJson);
      } catch (error) {
        console.error(error);
      }
    }
    if(!all){
    getMoviesFromApi()
  }
    if (all!==null&&info!==null&&location.hash === '#contact-page-home') {
      const onLoad = () => {
        if (location.hash === '#contact-page-home') {
          const contactSection = document.getElementById('contact-page-home');
          if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
          }
        }
      };

      // Check if the document is already fully loaded
      if (document.readyState === 'complete') {
        onLoad();
      } else {
        window.addEventListener('load', onLoad);
        return () => window.removeEventListener('load', onLoad);
      }
    }
  }, [all, info, location]);
  useEffect(() => {
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          "https://play.leerecs.com/api/home"
        );
        let responseJson = await response.json();
        console.log(responseJson)
        setInfo(responseJson.data);
      } catch (error) {
        console.error(error);
      }
    }
    getMoviesFromApi()

  },[])
  return (
    <div id="home-page">
      <div className="home-container">
      <Welcome info={info}/>
        {info&&info.HomeComponent.map((item)=>{
          if(item.type==="Welcome"){
            return 
          }
          if(item.type==="MusicianEvents"){
            return <MusicianEvents />
          }
          if(item.type==="SocialMedia"){
           return <SocialMedia  />
          }
          if(item.type==="PhysicalMedia"){
           return <PhysicalMedia />
          }
          if(item.type==="StartCareer"){
           return <StartCareer />
          }
          if(item.type==="Contact"){
          return <Contact  />
          }
          
        })}
        {/* <Footer/> */}
      </div>
    </div>
  );
}
