import { Link } from "react-router-dom";
export function ProductionMenuItems() {
  return (
    <div className="links d-flex">
      <a href="#landing-production">Welcome</a>
      <a href="#compare">Compare Results</a>
      <a href="#process">Work With Us</a>
      <Link to="/request">Request Review</Link>
      <a href="https://leerecs.com/" target="_blank" rel="noreferrer">
        Shop
      </a>
    </div>
  );
}

export function MainMenuItems(props) {
  return (
    <div className="links d-flex">
      {props&&props.info.info&&props.info.info.navLinks.map((item)=>{
       if(item.link==="/music"){
        return <Link to={item.link}>Music</Link>
       }
       if(item.name==="Shop"){
        return <a href={item.link} target="_blank" rel="noreferrer">
        Shop
      </a>
       }
       return <a href={item.link}>{item.name}</a>
      })}
      
    </div>
  );
}

export function SharedItems() {
  return (<div className="links d-flex" style={{marginRight:"230px", color:"black"}}>
  
  </div>)
}
