import { createSlice } from "@reduxjs/toolkit"

interface Claim {
    messageId:Number;
    userId:Number;
}

const initialState: Claim = {
    messageId:0,
    userId: parseInt(localStorage.getItem("userId") as string),
}

export const Index = createSlice({
    name: "uidata",
    initialState,
    reducers: {
        claim: (state,action) => {
            state.messageId=action.payload
        }
    },
})

export const { claim } = Index.actions

export default Index.reducer