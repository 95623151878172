import React from 'react'

export default function ClosePanelIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.196" height="21.196" viewBox="0 0 21.196 21.196">
      <g id="Group_72" data-name="Group 72" transform="translate(1.414 1.414)">
        <line id="Line_13" data-name="Line 13" y2="25.975" transform="translate(0 0) rotate(-45)" fill="none" stroke="#d2ded2" strokeLinecap="round" strokeWidth="2" />
        <line id="Line_14" data-name="Line 14" y2="25.975" transform="translate(18.367 0) rotate(45)" fill="none" stroke="#d2ded2" strokeLinecap="round" strokeWidth="2" />
      </g>
    </svg>
  )
}
