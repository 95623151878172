//@ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

interface Contributor {
  productionRedirect: Boolean;
}

const initialState: Contributor = {
  productionRedirect: false,
};

export const NavBarData = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    redirectElement: (state) => {
      state.productionRedirect = state;
    },
  },
});

export const { redirectElement } = NavBarData.actions;

export default NavBarData.reducer;
