// @ts-nocheck
import React, {  useState,useEffect } from "react";

//redux
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { setMusicShowPayWall,setAudioCounter, setMissingTrack, setInfoShowPage } from "../../../redux/data/uiData";
import ArrowDownIcon from "./src/ArrowDownIcon";

//helpers
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { useLocation } from "react-router-dom";

// icons

// extract color
import { usePalette } from "react-palette";
//styling
import "./index.scss";
import "./index.css";


const MusicOfficialPlayer = () => {
  const location = useLocation()
  const { musicData, playIndex } = useAppSelector(
    (state) => state.musicPlayerData
  );

  const {isLogedIn}=useAppSelector((state) => state.uiData)
  const dispatch = useDispatch()
  const [count,setCount]=useState(1)

 const [isUser,setIsUser]=useState(parseInt(localStorage.getItem("userId") as string))
 
useEffect(()=>{
  const audioElement = document.querySelector('.music-player-audio');
  
    const handleTimeUpdate = function() {

    if(!isLogedIn){
      if (audioElement.currentTime >= 60 * count) {
        audioElement.pause();
        setCount(count + 1);
        dispatch(setAudioCounter(count + 1))
        dispatch(setMusicShowPayWall(true))
      }
    }else{
      // Remove the existing event listener before adding a new one
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    }
  
      // Log the current time to the console (you can do whatever you want with this value)
       //console.log('Current time:', currentTime);
    };
    if (audioElement && !isUser) {
      // Remove the existing event listener before adding a new one
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
  
      // Add the updated event listener
      audioElement.addEventListener('timeupdate', handleTimeUpdate);
    }
  
    // Clean up the event listener when the component unmounts or when count changes
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };

},[count, isLogedIn, dispatch, isUser])

useEffect(()=>{
  
  const audioElement = document.querySelector('.music-player-audio');
  audioElement.addEventListener('error', function(event) {
    console.log('Audio file error, switching to default song.');
    dispatch(setInfoShowPage(location.pathname))
    dispatch(setMissingTrack(true))
    // Set the source to the default song
    this.src = 'https://res.cloudinary.com/dxkrgura6/video/upload/v1720185894/leerecs-merch-ad.mp3';
    // Load and play the default song
    this.load();
    this.play();
}, true);
},[dispatch, location.pathname])

useEffect(()=>{
  if(musicData){
  dispatch(setMissingTrack(false))}
},[musicData])
  const audioList1 = musicData;
  const options = {
    audioLists: audioList1,
    drag: false,
    showReload: false,
    showDownload: false,
    showPlay: true,
    showPlayMode: musicData.length - 1,
    showThemeSwitch: false,
    playIndex: (playIndex || 0) as number,
    showLyric: false,
    showDestroy: false,
    toggleMode: true,
    clearPriorAudioLists: true,
    autoPlayInitLoadPlayList: true,
    showMiniModeCover: false,
    autoHiddenCover: true,
    bounds: "parent",
  };

  // const location = useLocation();
  const canIshowPlayer = () => {
    let notAcceptedLocation = ["/login", "/signup", "/request"];
    if (notAcceptedLocation.includes(window.location.href)) {
      return false;
    } else {
      return true;
    }
  };

  // mobile player
  // stae management
  const [played, setplayed] = useState(1);
  const [showCover, setToggleCover] = useState(false);
  const [currentMusic, setcurrentMusic] = useState(0);

  // a function to return a random number between certain value
  const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
  // a function to return a good formatted seconds
  const fancyTimeFormat = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };
  const CORS_PROXY = "https://proxy.leerecs.com/?url=";
  const { data, loading, error } = usePalette(
    CORS_PROXY + musicData[currentMusic].cover
  );

  const colorBackground = (color1, color2) => {
    let interval = setInterval(function () {
      try {
        let element = document.querySelector(
          ".react-jinke-music-player-mobile"
        );
        let cover=document.querySelector(
          ".react-jinke-music-player-mobile-cover"
        );
        element.style.background = `linear-gradient(${color1}, ${color2})`;
        cover.style.background=musicData[playIndex].color;
        clearInterval(interval);
      } catch (error) { }
    }, 500);
  };
  return (
    <>
      <div id="MusicOfficialPlayer">
        {canIshowPlayer() && (
          <div className="top-index">
            {true && (
              <ReactJkMusicPlayer
                {...options}
                onCoverClick={() => setToggleCover(!showCover)}
                onModeChange={() => setToggleCover(false)}
                showMediaSession
                color={musicData[playIndex].color}
                mode="full"
              >
                {data && <div>{colorBackground(data.vibrant, data.muted)}</div>}
                
              </ReactJkMusicPlayer>
            )}
          </div>
        )}
      </div>

      <div
        className="song-cover"
        style={{ display: showCover ? "flex" : "none" }}
      >
        <div onClick={() => setToggleCover(false)}>
          <ArrowDownIcon />
        </div>
        <img src={musicData[currentMusic].cover} alt="cover" />
      </div>
    </>
  );
};

export default MusicOfficialPlayer;
