/* eslint-disable import/prefer-default-export */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssignData{
    name: string;
    id_attach:Number;
}

interface State {
    assignData: AssignData;
    
  }
  interface assignDataInput{
    value:AssignData;
}

const initialState: State = {
    assignData:  {
        name: "",
        id_attach:0,
        }
}

export const assignData = createSlice({
    name: "Assign State",
    initialState,
    reducers: {
      assignMUsic: (state, action: PayloadAction<assignDataInput>) => {
        //@ts-ignore
        state.assignData = action.payload.value;
      },
    },
  });

  export const { assignMUsic} = assignData.actions;
  export default assignData.reducer;