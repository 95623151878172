import { configureStore } from '@reduxjs/toolkit';
import appDataReducer from './data/home/index';
import contributorDataReducer from './data/navbar/index'
import uiDataReducer from "./data/uiData/index"
import musicPlayerReducer from "./data/musicPlayer/index"
import playlistReducer from "./data/playlist/index"
import searchReducer from "./data/search/index"
import claimReducer from "./data/claim/index"
import artistProfileReducer from "./data/artistProfile/index"
import productionPageReducer from "./data/redirectToProduction/index"
import assignMusicReducer from "./data/assignMusic/index"
import claimProfile from './data/claimProfile';

const store = configureStore({
  reducer: {
    appData: appDataReducer,
    contributorData: contributorDataReducer,
    uiData: uiDataReducer,
    musicPlayerData: musicPlayerReducer,
    playListPlayerData: playlistReducer,
    search: searchReducer,
    claim: claimReducer,
    artistProfile: artistProfileReducer,
    productionRedirect: productionPageReducer,
    assignMusic:assignMusicReducer,
    claimProfile:claimProfile
  },
});

export type RootState=ReturnType<typeof store.getState>

export type AppDispatch=typeof store.dispatch
export default store
