import React from 'react'
import radio from "../../../../../radio.svg"

export default function Radio() {
  return (
    <div className='mr-3' style={{background:`url(${radio})`, width:"30px", height:"30px", backgroundSize:"cover" ,color:"white"}}>
      
    </div>
  )
}
