import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MusicInfo {
    music_file: Number;
    music_thumbnail: Number;
    music_artist: Number;
}

const initialState: MusicInfo = {
    music_file: 0,
    music_thumbnail: 0,
    music_artist: 0,
}

export const PlayListData = createSlice({
    name: "uidata",
    initialState,
    reducers: {
        addMusic: (state, action: PayloadAction<MusicInfo>) => {
            state.music_file= action.payload.music_file;
            state.music_thumbnail = action.payload.music_thumbnail;
            state.music_artist = action.payload.music_artist;
        }
    },
})

export const { addMusic } = PlayListData.actions

export default PlayListData.reducer