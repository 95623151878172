// @ts-nocheck
import React from 'react';
import Loader from '../signup/src/icons/loader'

export default function Loading(props) {
  return (
      <div className="col-md-12 px-0">
        <div className="btn-loading px-3">
          
            <Loader />
          
          <div className="col-md-10 text-loading">
            <div>{props.text || "loading"} </div>
          </div>
        </div>
      </div>
  );
}
