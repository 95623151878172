import React, { useState } from "react";

import { useKeenSlider } from "keen-slider/react";
import SingleMusic from "./SingleMusic";
import "../../../../../node_modules/keen-slider/keen-slider.min.css";
import "./Carousel.scss";
import ForwardIcon from "./assets/ForwardIcon";
import BackwardIcon from "./assets/BackwardIcon";
import { useQuery, gql } from "@apollo/client";

export default function FeauturedMusicCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const QUERY = gql`
    query {
      AllFeaturedMusic {
        title
        thumbnail
        file
        username
        musicId
        imageId
        memberId
        topicId
        anonymous
        messageId
        profile
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(QUERY);
  return (
    <>
      {data && (
        <>
          {" "}
          {data.AllFeaturedMusic.length !== 0 && (
            <>
              <div id="carousel-wrapper">
                <div className="d-flex">
                  {data.AllFeaturedMusic.length - 1 !== 0 &&
                    loaded &&
                    instanceRef.current && (
                      <div id="arrow-left">
                        <div
                          onClick={(e) => {
                            e.stopPropagation() || instanceRef.current?.prev();
                          }}
                          disabled={currentSlide === 0}
                        >
                          <BackwardIcon />
                        </div>
                      </div>
                    )}

                  <div
                    ref={sliderRef}
                    className="keen-slider"
                    id="carousel-element"
                    style={{}}
                  >
                    {data &&
                      data.AllFeaturedMusic.map((item) => {
                        return (
                          <div
                            className="keen-slider__slide number-slide1"
                            style={{
                              minWidth: "100%!important",
                              maxWidth: "100%!important",
                            }}
                          >
                            <SingleMusic
                              image={item.thumbnail}
                              title={item.title}
                              file={item.file}
                              username={item.username}
                              profile={item.profile}
                            />
                          </div>
                        );
                      })}
                  </div>
                  {data.AllFeaturedMusic.length - 1 !== 0 &&
                    loaded &&
                    instanceRef.current && (
                      <div id="arrow-right">
                        <div
                          onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.next()
                          }
                          disabled={
                            currentSlide ===
                            instanceRef.current.track.details.slides.length - 1
                          }
                        >
                          <ForwardIcon />
                        </div>
                      </div>
                    )}
                </div>
                {data.AllFeaturedMusic.length - 1 !== 0 &&
                  loaded &&
                  instanceRef.current && (
                    <div className="dots">
                      {[...Array(data.AllFeaturedMusic.length).keys()].map(
                        (idx) => {
                          return (
                            <div
                              key={idx}
                              onClick={() => {
                                instanceRef.current?.moveToIdx(idx);
                              }}
                              className={
                                "dot" + (currentSlide === idx ? " active" : "")
                              }
                            ></div>
                          );
                        }
                      )}
                    </div>
                  )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
