// @ts-nocheck
import React from 'react'

import Player from "./src/Player";

//redux hooks
import { useAppDispatch } from "../../../../redux/hooks"
import {useHistory} from "react-router-dom"

//redux actions
import { playSingle } from "../../../../redux/data/musicPlayer/index"
import "./index.scss"
import { addMusic } from '../../../../redux/data/home';

const TopMusic = ({ imageUrl, musicUrl, artist, musicName, dataType, changeSong}) => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    return (
        <div className="card-obj card1" style={{backgroundImage:`url("${imageUrl}")`}} onClick={(e)=>{
            e.preventDefault()
            if (dataType==="music"){
                dispatch(playSingle({
                    value: [{
                        name: musicName,
                        singer: artist,
                        cover: imageUrl,
                        musicSrc: musicUrl,
                    }]
                }))
            }
            
            if (dataType === "playlist"){
                history.push(`/playlist/${musicUrl}`)
            }
            if (dataType === "podcast"){
                history.push(`/podcast/${musicName}`)
                changeSong(musicName)
            }
        }}>
            <div className="player d-flex justify-content-end" onClick={()=>{
                console.log(musicUrl)
                dispatch(playSingle({
                    value: [{
                        name: musicName,
                        singer: artist,
                        cover: imageUrl,
                        musicSrc: musicUrl,
                    }]
                }))
            }}>
                <Player />
            </div>
            <div className="bottom-text-events">
                <div className="music-title-events">
                    {musicName}
                </div>
                <div className="music-artist-events">{artist ?artist:"leerecs"}</div>
            </div>
        </div>
    )
}

export default TopMusic
