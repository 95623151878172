/* eslint-disable import/prefer-default-export */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';

interface MusicData{
    name: string;
    singer: string,
    cover:string;
    musicSrc:string;
    color?:string;
}
interface MusicProfile{
  title:string;
  description:string;
  imageUrl:string;
}
interface State {
  musicData: MusicData[];
  musicProfile:MusicProfile;
  playIndex:Number;
  musicId?:number;
}
interface singleDataInput{
    value:MusicData[];
}
interface musicDataInput{
  value:MusicProfile;
}
interface musicIndexInput{
    value:number;
}


const initialState: State = {
  musicData: [
    {
      name: "",
      singer: "",
      cover: "",
      musicSrc: "",
      color:""
    },
  ],
  musicProfile:{
    title:"",
    description:"",
    imageUrl:""
  },
  playIndex:0,
  musicId:0,
};

export const appData = createSlice({
  name: "Music State",
  initialState,
  reducers: {
    playSingle: (state, action: PayloadAction<singleDataInput>) => {
      ReactGA.event(
        {
          category: 'Music', // You can choose your own category name
          action: 'Played Music', // You can name the action as you like
          label: 'Played Music' // Optional, to provide more details about the event
        }
      )
      //@ts-ignore
      state.musicData = action.payload.value;
      // alert(state.musicData[0].musicSrc)
    },
    setIndex: (state, action: PayloadAction<musicIndexInput>) => {
      state.playIndex = action.payload.value;
    },
    setMusicId:(state, action: PayloadAction<musicIndexInput>)=>{
      // console.log(action.payload.value)
      state.musicId=action.payload.value
    },
    setMusicProfile:(state,  action:PayloadAction<musicDataInput>)=>{
      state.musicProfile= action.payload.value
    },
  },
});

export const { playSingle, setIndex, setMusicId, setMusicProfile } = appData.actions;

export default appData.reducer;
