import { createSlice, current } from "@reduxjs/toolkit"
interface assign{
    type:String
    id:number
}
interface albumData{
    albumName:string;
    owner:string
    url:string
    userId:string,
    message:string,
    requester:string,
    action:'REMOVE'|"ADD"|"CLAIM"|"REQUEST"|"REQUEST-ACTIVITY"
}

interface Contributor {
    deleteMusic: boolean
    addplaylist:boolean
    addAlbum:boolean
    claimMusic:boolean
    claimProfile:boolean
    assignMusic:boolean
    updateAttachment:boolean
    PlaylistState:boolean
    albumState:'new'|'add'
    updateBtn:boolean
    callupadtefunction:boolean
    infobanner:boolean
    missingTrack:boolean
    infoShowPage:String
    currentAlbum:number
    currentPlaylist:number
    updateAudio:boolean
    idAttach:number
    deletePlaylist:boolean
    deleteAlbum:boolean
    assignPlaylist:assign|null
    showFriends:boolean
    isMini:boolean
    showPaywall:boolean
    showMusicPayWall:boolean
    audioCounter:number
    isThumbnail:boolean
    showAlbumConfig:boolean
    albumData:albumData
    isLogedIn:boolean
}

const initialState: Contributor = {
   deleteMusic: false,
   addplaylist: false,
   addAlbum:false,
   claimMusic:false,
   claimProfile:false,
   assignMusic:false,
   updateAttachment:false,
   PlaylistState:false,
   updateBtn:false,
   callupadtefunction:false,
   infobanner:false,
   updateAudio:false,
   idAttach:0,
   infoShowPage:"",
   albumState:'new',
   currentAlbum:1,
   currentPlaylist:1,
   deletePlaylist:false,
   deleteAlbum:false,
   assignPlaylist:null,
   showFriends:false,
   isMini:false,
   isThumbnail:true,
   showPaywall:false,
   showMusicPayWall:false,
   audioCounter:0,
   showAlbumConfig:false,
   isLogedIn:false,
   albumData:{
    albumName:'',
    owner:'',
    url:'',
    message:'',
    userId:" ",
    requester:"",
    action:'ADD'
   },
   missingTrack:false

}

export const NavBarData = createSlice({
    name: "uidata",
    initialState,
    reducers: {
        panel: (state) => {
            state.deleteMusic = !state.deleteMusic
        },
        playlist: (state) => {
            state.addplaylist = !state.addplaylist
        },
        album: (state) => {
            state.addAlbum = !state.addAlbum
        },
        claimMusic: (state) => {
            state.claimMusic = !state.claimMusic
        },
        claimProfile: (state) => {
            state.claimProfile = !state.claimProfile
        },
        assignMusicUi: (state) => {
            state.assignMusic = !state.assignMusic
        },
        updateAttachmentUi:(state)=>{
            
            state.updateAttachment =!state.updateAttachment
        },
        setPlaylistState:(state,action)=>{
            
            state.PlaylistState =action.payload
            // console.log(state.PlaylistState)
        },
        setAlbumState:(state,action)=>{
            state.albumState =action.payload
        },
        setUpdateBtn:(state,action)=>{
             state.updateBtn = action.payload
        },
        callUpdateFunction:(state)=>{
            state.callupadtefunction = ! state.callupadtefunction
        },
        setInfoBanner:(state)=>{
         state.infobanner=!state.infobanner;
        },
        setInfoShowPage:(state,action)=>{
            state.infoShowPage = action.payload
        },
        setCurrentAlbum:(state,action)=>{
        state.currentAlbum = action.payload
        },
        setCurrentPlaylist:(state,action)=>{
            state.currentPlaylist = action.payload
        },
        setUpdateAudioFile:(state)=>{
          state.updateAudio = !state.updateAudio;
        },
        setIdAttach:(state,action)=>{
            state.idAttach = action.payload
        },
        setDeletePlaylist:(state)=>{
        state.deletePlaylist = !state.deletePlaylist
        },
        setDeleteAlbum:(state)=>{
        state.deleteAlbum = !state.deleteAlbum
        },
        setAssignPlaylist:(state,action)=>{
            state.assignPlaylist = action.payload
        },
        setShowFriends:(state)=>{
         state.showFriends = !state.showFriends
        },
        setIsMini:(state)=>{
            state.isMini = !state.isMini
        },
        setIsThumbnail:(state)=>{
            state.isThumbnail = !state.isThumbnail
        },
        setShowPayWall:(state,action)=>{
        state.showPaywall=action.payload
    },
    setMusicShowPayWall:(state,action)=>{
        state.showMusicPayWall=action.payload
    },
    setAudioCounter:(state,action)=>{
        state.audioCounter=action.payload
    },

    setShowAlbumConfig:(state,action)=>{
        state.showAlbumConfig=action.payload
    },
    setAlbumData:(state,action)=>{
        state.albumData=action.payload
    },
    setIsLogedIn:(state,action)=>{
     state.isLogedIn = action.payload
    },
    setMissingTrack:(state,action)=>{
        state.missingTrack = action.payload
    }
    
}
})

export const { panel, playlist, album, claimMusic,claimProfile,assignMusicUi,updateAttachmentUi,setPlaylistState,setUpdateBtn,callUpdateFunction, setInfoBanner,setInfoShowPage,setAlbumState,setCurrentAlbum,setCurrentPlaylist,setUpdateAudioFile,setIdAttach,setDeletePlaylist,setDeleteAlbum,setAssignPlaylist,setShowFriends,setIsMini,setIsThumbnail,setShowPayWall,setMusicShowPayWall,setAudioCounter,setShowAlbumConfig, setAlbumData,setIsLogedIn,setMissingTrack} = NavBarData.actions

export default NavBarData.reducer