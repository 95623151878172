import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import Backward from "./src/Backward";
import Forward from "./src/Forward";
import Pause from "./src/Pause";
import Play from "./src/Play";
import "./wave.scss"
import { v4 as uuidv4 } from "uuid";


const WaveFormPlayer = (props) => {

  const waveformRef = useRef();
  const trackRef = useRef();

  const [waveSurfer, setWaveSurfer] = useState(null);
  const [playingAudio, setPlayingAudio] = useState(false);

  const playAudio = () => {
    if (!props.hideWave) waveSurfer.play();
    else trackRef.current.play();
    setPlayingAudio(true);
  };

  const pauseAudio = () => {
    if (!props.hideWave) waveSurfer.pause();
    else trackRef.current.pause();
    setPlayingAudio(false);
  };

  const seekAudioTenSeconds = (ahead) => {
    if (ahead) trackRef.current.currentTime += 10;
    else trackRef.current.currentTime -= 10;
  };

  useEffect(() => {
    if (waveSurfer == null) {
      // First render
      const wavesurfer = props.waveStyles
        ? WaveSurfer.create({
            // ...props.waveStyles,
            container: waveformRef.current,
            responsive: true,
            backend: "MediaElement",
            waveColor: props.backColor,
            progressColor: props.color,
          })
        : WaveSurfer.create({
            container: waveformRef.current,
            responsive: true,
            backend: "MediaElement",
            waveColor: props.backColor,
            progressColor: props.color,
          });
      setWaveSurfer(wavesurfer);
      wavesurfer.load(trackRef.current);
    } else {
      // Song changed
      waveSurfer.load(trackRef.current);
      setPlayingAudio(false);
    }
  }, [props.audioUrl]);

  useEffect(() => {
    if (waveSurfer !== null) {
      if (props.play) {
        playAudio();
      } else {
        pauseAudio();
      }
    }
  }, [props.play]);
  

  return (
    <>
      <div>
        <div>
          <div>
            {!props.hideWave && <div ref={waveformRef} id={uuidv4()} />}
            <audio src={props.audioUrl} ref={trackRef} />
          </div>
          <div className="container" id="wave-style">
            <div className="all-btn">
              <div className="btn-1" onClick={() => seekAudioTenSeconds(false)}>
                <Backward />
              </div>

              {playingAudio ? (
                <div
                  className="btn-1"
                  onClick={() => (playingAudio ? pauseAudio() : playAudio())}
                >
                  <Pause />
                </div>
              ) : (
                <div
                  className="btn-1"
                  onClick={() => (playingAudio ? pauseAudio() : playAudio())}
                >
                  <Play />
                </div>
              )}
              <div className="btn-1" onClick={() => seekAudioTenSeconds(true)}>
                <Forward />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaveFormPlayer;
