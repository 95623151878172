import React from "react";

export default function BackwardIcon() {
  return (
    <div
      style={{
        minHeight: "60px",
        minWidth: "60px",
        borderRadius: "50%",
        maxHeight: "60px",
        maxWidth: "60px",
        backdropFilter:"blur(35px)",
        border:"solid 2px #fff",
        display:"grid",
        placeItems:"center"
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.828"
        height="9.914"
        viewBox="0 0 17.828 9.914"
        style={{transform:"rotate(90deg)"}}
      >
        <path
          id="Path_243"
          data-name="Path 243"
          d="M0,0,7.5,7.5,15,0"
          transform="translate(1.414 1.414)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
