// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Parser from "rss-parser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch } from "../../../../redux/hooks";
import { addMusic } from "../../../../redux/data/home";

import TopMusic from "./topMusic";

import "./index.scss";
import { setShowPayWall } from "../../../../redux/data/uiData";


const MusicianEvents = () => {
  const dispatch = useAppDispatch();
  const [isUser,setIsUser]=useState(parseInt(localStorage.getItem("userId") as string))
  const [music, setMusic] = useState<MusicState[]>([]);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [info, setinfo] = useState(null);
  useEffect(() => {
    async function getMoviesFromApi() {
      try {
        let response = await fetch(
          "https://play.leerecs.com/api/content"
        );
        let responseJson = await response.json()
        setinfo(responseJson.data.spotlight)
      } catch (error) {
        //console.error(error);
      }
    }
    getMoviesFromApi()

    const handleFeed = async () => {
      let feed = await await fetch("https://play.leerecs.com/api/podcasts")
      const data = await feed.json();
      var counter = 0;
      var pageCounter = 0;
      var intermediateArray = [];
      if (data) {
        var musicArray = [];
        data.data.forEach((item, idx) => {
          if (idx === 0) {
            dispatch(
              addMusic({
                imageUrl: item.imageUrl,
                musicUrl: item.musicUrl,
                artist: "leerecs",
                musicName: item.title as string,
              })
            );
          }
          if (counter === 4) {
            musicArray.push(intermediateArray);
            counter = 0;
            pageCounter = pageCounter + 1;
            intermediateArray = [];
          }
          if (counter <= 4) {
            counter = counter + 1;
            intermediateArray.push({
              musicUrl: item.musicUrl,
              musicName: item.title,
              imageUrl: item.imageUrl,
              artist: "leerecs",
            });
          }
        });
        if (musicArray.length > 0 && music.length === 0) {
          setMusic(musicArray);
          setpageCount(musicArray.length);
        } else {
          return null;
        }
      }
    };
    handleFeed();
  }, [music]);

  const ListOfMusic = () => {
    return music[currentPage].map((singleMusic, idx) => {
      return (
        <TopMusic
        key={idx}
          imageUrl={singleMusic.imageUrl}
          musicUrl={singleMusic.musicUrl}
          artist={singleMusic.artist}
          musicName={singleMusic.musicName}
          dataType={"music"}
        />
      );
    });
  };

  const listOfPages = () => {
    return (
      <div className="pagination-buttons mt-3" style={{zIndex:"1000"}}>
        {Array.from(Array(pageCount).keys()).map((singleCount, idx) => {
          if(idx<3){
          return (
            <div key={idx}
              onClick={(e) => {
                
                if(idx>0 &&!isUser){
                  dispatch(setShowPayWall(true))
                }else{
                  setcurrentPage(idx);
                }
              }}
              className={`${idx === currentPage && "active"}`}
            >
            </div>
          );}
        })}
      </div>
    );
  };

  return (
    <div className="container-fluid" id="events">
      <div className="header">
        <div className="">
          <h1 className="text-lg text-light main-title">
            {info&&info.title}
          </h1>
          <p className="description-text">
          {info&&info.description}  
          {!isUser&&(<span  onClick={()=>{
            dispatch(setShowPayWall(true))
          }} className="moreLink"> See all episode <FontAwesomeIcon icon={['fas', 'arrow-right']} /></span>)}
          {!!isUser&&(<Link to="https://ppdcast.leerecs.com/podcast"><span className="moreLink"> See all episode <FontAwesomeIcon icon={['fas', 'arrow-right']} /></span></Link>)}
          
          </p>
        </div>

        <div className="pagination d-flex flex-column justify-content-end">
          {pageCount > 0 && <div className="">{listOfPages()}</div>}
        </div>
      </div>

      {music.length > 0 && <div className="box-wrapper m-top">{ListOfMusic()}</div>}
    </div>
  );
};

export default MusicianEvents;
