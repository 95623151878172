import {createSlice} from "@reduxjs/toolkit"

interface Contributor{
    becomeContributor:String
}

const initialState:Contributor={
    becomeContributor:localStorage.getItem("musician") as String
}

export const NavBarData=createSlice({
    name:"navbar",
    initialState,
    reducers:{
        hideContributeBtn:(state)=>{
            state.becomeContributor = localStorage.getItem("musician") as String;
        },
    },
})

export const { hideContributeBtn }=NavBarData.actions

export default NavBarData.reducer