/* eslint-disable import/prefer-default-export */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface claimData{
    real_name: string;
    title:string
}

interface State {
    claimData: claimData;
    
  }
  interface assignDataInput{
    value:claimData;
}

const initialState: State = {
    claimData:  {
        real_name: "",
        title:""
        }
}

export const claimedProfileData = createSlice({
    name: "Profile State",
    initialState,
    reducers: {
      claimData: (state, action: PayloadAction<assignDataInput>) => {
        //@ts-ignore
        state.claimData = action.payload.value;
      },
    },
  });

  export const { claimData} = claimedProfileData.actions;
  export default claimedProfileData.reducer;