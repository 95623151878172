import './workings.scss'

import blueBackground from './assets/Group 1.png'
import purpleBackground from './assets/Group 2.png'
import orangeBackground from './assets/Group 3.png'
import greenBackground from './assets/Group 4.png'
import headphonesIcon from './assets/headphones_icon.svg'
import networkIcon from './assets/network_icon.svg'
import playIcon from './assets/play_icon.svg'
import starIcon from './assets/star_icon.svg'
import WorkingsCard from './components/WorkingsCard';
import Footer from "../../../main/components/footerSection/Footer";
import { Testimonial } from '../testimonial'
import Cta from '../cta/Cta'

function Workings({ data }) {
  return (
    <div className="workings-page-wrapper text-lg text-light" id="process">
      <div className="workings-page-content">
        <div className="workings-title">
          <h1 className="text-lg text-light main-title">
            {data.process.title}
          </h1>
          <p>{data.process.subTitle}</p>
        </div>

        <div className="workings-list">
          <div
            className="workings-card-wrapper"
            style={{ backgroundImage: `url('${blueBackground}')` }}
          >
            <div className="workings-icon">
              <img src={data.process.one.icon} alt="icon" />
            </div>
            <h5>{data.process.one.title}</h5>
            <p>{data.process.one.description}</p>
          </div>

          <div
            className="workings-card-wrapper"
            style={{ backgroundImage: `url('${purpleBackground}')` }}
          >
            <div className="workings-icon">
              <img src={data.process.two.icon} alt="icon" />
            </div>
            <h5>{data.process.two.title}</h5>
            <p>{data.process.two.description}</p>
          </div>

          <div
            className="workings-card-wrapper"
            style={{ backgroundImage: `url('${orangeBackground}')` }}
          >
            <div className="workings-icon">
              <img src={data.process.three.icon} alt="icon" />
            </div>
            <h5>{data.process.three.title}</h5>
            <p>{data.process.three.description}</p>
          </div>

          <div
            className="workings-card-wrapper"
            style={{ backgroundImage: `url('${greenBackground}')` }}
          >
            <div className="workings-icon">
              <img src={data.process.four.icon} alt="icon" />
            </div>
            <h5>{data.process.four.title}</h5>
            <p>{data.process.four.description}</p>
          </div>
        </div>
        <Testimonial />
        <Cta />
      </div>
      <Footer />
    </div>
  );
}

export default Workings
